'use strict';

import '@babel/polyfill';
import inView from 'in-view'
import 'jquery-inview'
import 'bootstrap/js/dist/scrollspy'
require("picturefill")
import 'objectFitPolyfill'

import createjs from 'preload-js'
import Swiper from 'swiper/bundle'
import ScrollHelper from './_ScrollHelper.js'
import ResizeHelper from './_ResizeHelper.js'

inView.offset(window.innerHeight * 0.25);
const $body = $('body');
const $window = $(window);
class Preloader{
  constructor(){
    this.preload = new createjs.LoadQueue();
    this.checkCodecs();
    this.listen();
    this.parse();
  }
  checkCodecs(){

    var media = document.createElement("video");
    // this.canplaymp4 = media.canPlayType(`video/mp4; codecs="avc1.42E01E, mp4a.40.2"`)
    this.canplaywebm = media.canPlayType(`video/webm; codecs="vp9"`)

  }
  parse(){
    const preloadItemList = []

    // img
    const preloadImages = document.querySelectorAll('.preload-image');
    for(const image of preloadImages){
      preloadItemList.push(this.getImageSrc(image));
    }

    // video
    const preloadVideos = document.querySelectorAll('.preload-video');
    for(const video of preloadVideos){
      preloadItemList.push(this.getVideoSrc(video));
    }

    this.fetch(preloadItemList);
  }
  fetch(preloadSrcList){
    window.addEventListener('mousewheel', this.scrollCancell, {passive: false})
    this.preload.loadManifest(preloadSrcList);
  }
  scrollCancell(e){
    // e.preventDefault();
    // e.stopPropagation();
    // return false;
  }
  listen(){
    const $progressbar = $('#progress-bar');
    this.preload.addEventListener('progress', progress => {
      $progressbar.width((progress.loaded * 100) + '%');
    });
    this.preload.addEventListener('error', () => {
      window.removeEventListener('mousewheel', this.scrollCancell);
    });
    this.preload.addEventListener('camplaythrough', items => {
      console.log('camplaythroughcamplaythroughcamplaythroughcamplaythrough');
    })
    this.preload.addEventListener('complete', items => {
      setTimeout(() => {
        $body.addClass('loaded');
        window.removeEventListener('mousewheel', this.scrollCancell);
        this.onload()
        
      }, 500)
      setTimeout(() => {
        if($window.width() >= 960){
          $body.addClass('menu-active');
        }
        $body.addClass('animated')
      }, 1700);

      $('.video-inview').each((index, el) => {
        try{
          el.load();
        }catch(e){

        }
      })
    });
  }
  getURLOrigin(path){
    const origin = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
    return origin + path;
  }
  getImageSrc(image){
    let imageSrc = '';
    if(window.devicePixelRatio >= 2 && image.srcset){
      // image.srcset.split(',').forEach(singleSrc => {
      // });

      for(const singleSrc of image.srcset.split(',')){
        if(singleSrc.lastIndexOf('2x') != -1){
          imageSrc = this.getURLOrigin(singleSrc.split(' ')[0]);
        }
      }
    }else{
      imageSrc = image.src;
    }
    return imageSrc;
  }
  getVideoSrc(video){
    return video.getAttribute('src')
    // let videoSrc = '';
    // const sources = video.querySelectorAll('source');
    // for(let i = 0; i < sources.length; i+= 1){
    //   const source = sources[i];
    //   if(this.canplaywebm && source.getAttribute('type') === `video/webm; codecs="vp9"`){
    //     videoSrc = source.src;
    //     break;
    //   }
    //   else{
    //     videoSrc = source.src;
    //   }
    // }
    // return videoSrc;
  }
  onload(fn) {
    // console.log('onload')
  }
}

class Sticky {
  constructor(el, target) {
    this.windowHeight = window.innerHeight
    this.endPosition = document.getElementById(target).offsetTop

    const resizeHelper = new ResizeHelper(() => {
      // console.log(this.windowHeight, this.endPosition)
      this.windowHeight = window.innerHeight
      this.endPosition = document.getElementById(target).offsetTop
    })

    const is_ie = window.matchMedia('all and (-ms-high-contrast: none)')
    const is_pc = window.matchMedia('screen and (min-width:768px)')


    const $body = document.querySelector('body')
    const scroll = new ScrollHelper(e => {
      const offset = window.innerHeight * 0.25
      const top = window.pageYOffset || document.documentElement.scrollTop
      const is_sticky = top + this.windowHeight >= this.endPosition
      document.getElementById(el).classList.toggle('sticky', is_sticky)
      if(is_sticky && !is_ie.matches) {
        const top = $('#kv').outerHeight() + $('#about').outerHeight() - $('#background_video').outerHeight()
        $('#background_video').css('top', top)
      } else {
        $('#background_video').css('top', 0)
      }

    })
  }
}

$(() => {
  // $body.addClass('ready')
  document.body.classList.add('ready')
  // const preloader = new Preloader();
  // preloader.onload = () => {
  //   // console.log('load and play video')
  //   document.getElementById('video_01').play()
  //   //inview ---------------
  //   $('.video-inview').on('inview', (event, isInView) => {
  //     if(isInView){
  //       event.currentTarget.play()
  //     }else{
  //       event.currentTarget.pause()
  //     }
  //   });
  // }
  document.getElementById('video_01').addEventListener('canplaythrough', e => {
    document.getElementById('video_01').play()
    document.body.classList.add('loaded')
  })

  $('.video-inview').on('inview', (event, isInView) => {
    // $body.addClass('menu-active');
    if(isInView){
      event.currentTarget.play()
    }else{
      event.currentTarget.pause()
    }
  })




  const sticky = new Sticky('background_video', 'shikumi');
  // setTimeout(() => {

  // anime({
  //   targets: '.keyvisual_logo .lines path',
  //   strokeDashoffset: [anime.setDashoffset, 0],
  //   easing: 'easeInOutSine',
  //   duration: 1500,
  //   delay: function(el, i) { return i * 250 },
  //   direction: 'alternate',
  //   loop: true
  // });

  // }, 2000)
  // let windowHeight = window.innerHeight;
  // const resizeHelper = new ResizeHelper(() => {
  //   windowHeight = window.innerHeight;
  // })

  // const $body = document.querySelector('body')
  // const scroll = new ScrollHelper(e => {
  //   const offset = window.innerHeight * 0.25
  //   const top = window.pageYOffset || document.documentElement.scrollTop
  //   if (top >= offset) {
  //     $body.classList.add('scrolled')
  //   } else {
  //     $body.classList.remove('scrolled')
  //   }
  // })

  // modal
  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  var player;
  window.onYouTubeIframeAPIReady = () => {
    player = new YT.Player("kv_video", {})
  }
  $('.js-modal').on('click', e => {
    const modal = document.getElementById(e.currentTarget.getAttribute('data-target'))
    modal.classList.toggle('open')
    // console.log(player);
    player.stopVideo()
    // e.preventDefault()
    // e.stopPropagation()
    // return false
  })

  // gallery ---------------
  const $readout = $('.js-gallery-readout')
  const gallery_length = $('.js-gallery-slides').length
  const gallery_slider = new Swiper('.js-gallery-slider', {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 0,
    speed: 1000,
    // centeredSlides: true,
    // noSwiping: true,
    // allowTouchMove: false,
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    effect: 'fade',
    

    on: {
      slideChange: (swiper, current, total) => {
        const index = swiper.realIndex
        $readout.text(index + 1 + '/' + gallery_length)
      },
    },
    breakpoints: {
      960: {
        noSwiping: true,
        allowTouchMove: false,
        pagination: {
          el: '.featureGallery_bullets',
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="featureGallery_bullets-item ${className}"><span><img src="/assets/images/gallery/thumbnail${index + 1}.jpg"></span></span>`
            // return '<span class="' + className + '">' + (index + 1) + '</span>';
          },
        },
      },
    },
    navigation: {
      nextEl: document.querySelector('.js-gallery-slider .swiper-button-next'),
      prevEl: document.querySelector('.js-gallery-slider .swiper-button-prev'),
    },
  })
  gallery_slider.autoplay.stop()

  $('.js-gallery-slider').on('inview', (event, isInView) => {
    if(isInView){
      gallery_slider.autoplay.start()
    }else{
      gallery_slider.autoplay.stop()
    }
  });
  

  $(window).on('scroll', function(e) {
    inView('.inview')
      .on('enter', function(el) {
        $(el).addClass('is-inview');
      })
  });
  //firstview
  $('.inview').each(function(index, el) {

    // dev
    // setInterval(() => {
    //   $(el).toggleClass('is-inview')
    // }, 3000)

    if($(el).offset().top < $(window).innerHeight()) {
      $(el).addClass('is-inview')
    }else {
      return false;
    }
  });


  // $(window).on('click', () => {
  //   $('.is-inview').toggleClass('is-inview')
  // })
  
});
